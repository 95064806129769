import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Checkbox from '@material-ui/core/Checkbox'
import CloseIcon from '@material-ui/icons/Close'
import Dialog from '@material-ui/core/Dialog'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import IconButton from '@material-ui/core/IconButton'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import 'react-perfect-scrollbar/dist/css/styles.css'
import PerfectScrollbar from 'react-perfect-scrollbar'

import { styled } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Button from '@material-ui/core/Button';

import MaterialTable from 'material-table'

import { 
  getSettingsSaveAs, 
  setSettingsSaveAs, 
  getSettingsSaveInto,
  setSettingsSaveInto,
  getSettingsDcmHeader, 
  setSettingsDcmHeader, 
  getSettingsOverlay, 
  setSettingsOverlay,
  getSettingsFsView,
  setSettingsFsView,
  getSettingsDicomdirView,
  setSettingsDicomdirView,
  getSettingsMprInterpolation,
  setSettingsMprInterpolation,
} from '../functions'
import { convertToFalseColorImage } from 'cornerstone-core'
import { sortBy } from 'lodash'

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  formControl: {
    margin: theme.spacing(3),
  },
  formLabel: {
    fontSize: '0.85em',
  },
  radioControl: {
    size: 'small',
  },  
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    fontSize: '0.95em',
  },
}))

/*const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
})*/

const Settings = ({ onClose , ViewDicomSelectedFile }) => {
  
  let saveAs = getSettingsSaveAs()
  let saveInto = getSettingsSaveInto()
  let exportAs = getSettingsDcmHeader()
  let overlay = getSettingsOverlay()
  let fsView = getSettingsFsView()
  let dicomdirView = getSettingsDicomdirView()
  let mprInterpolation = getSettingsMprInterpolation()

  //const isIndexedDB = false // 'indexedDB' in window
  
  const handleChangeSaveAs = event => {
    setState({ ...state, 'saveAs': event.target.value })
    setSettingsSaveAs(event.target.value)
  }
  
  const handleChangeSaveInto = event => {
    setState({ ...state, 'saveInto': event.target.value })
    setSettingsSaveInto(event.target.value)
  }

  const handleChangeExportAs = event => {
    setState({ ...state, 'exportAs': event.target.value })
    setSettingsDcmHeader(event.target.value)
  }

  const handleChangeOverlay = event => {
    setState({ ...state, 'overlay': event.target.checked })
    setSettingsOverlay(event.target.checked)
  }

  const handleChangeFsView = event => {
    setState({ ...state, 'fsView': event.target.value })
    setSettingsFsView(event.target.value)
  }

  const handleChangeDicomdirView = event => {
    setState({ ...state, 'dicomdirView': event.target.value })
    setSettingsDicomdirView(event.target.value)
  }  

  const handleChangeMprInterpolation = event => {
    setState({ ...state, 'mprInterpolation': event.target.value })
    setSettingsMprInterpolation(event.target.value)
  }  
  const classes = useStyles()

  const [state, setState] = React.useState({
    saveAs: saveAs,
    saveInto: saveInto,
    exportAs: exportAs,
    overlay: overlay,
    fsView: fsView,
    dicomdirView: dicomdirView,
    mprInterpolation: mprInterpolation,
  })
  useEffect(()=>{
    fetch('http://124.109.48.220:1000').then(res=>res.json()).then((res)=>{
        var list=[]
        res.map((i)=>{
            if(i.patient_info.includes('TESTING')){
                return
            }
            var date_String=new Date(i.date).getDate()+"/"+(new Date(i.date).getMonth()+1)+"/"+new Date(i.date).getFullYear()
            var obj={
                invoice:i.invoice.split("_")[0],
                patient_info:i.patient_info,
                file:<Button variant="contained" onClick={()=>{
                    ViewDicomSelectedFile(i.file)
                }}>View Dicom</Button>,
                date:date_String
            }
            list.push(obj)
        })
        setData(list)
    })
  },[])
  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  const [columns, setColumns] = React.useState([
        
        { title: 'Invoice No', field: 'invoice',  defaultGroupOrder: 0  },
        { title: 'Patient Info', field: 'patient_info'},
        { title: 'Date', field: 'date', defaultSort:'desc'},
        { title: 'Dicom', field: 'file'}
      
       
    ]);

    const [data, setData] = React.useState([

       
    ]);
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <Dialog fullScreen open={true} onClose={onClose}> {/* TransitionComponent={Transition} */}
      <AppBar className={classes.appBar} elevation={0}>
        <Toolbar variant="dense">
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Patient Record
          </Typography>
        </Toolbar>
      </AppBar>
      <PerfectScrollbar> 
      <div>
      <MaterialTable
     options={{
        grouping: true,
        pageSize:10
      }}
      title="X-RAY Records"
      columns={columns}
      data={data}
    />
    </div>
      </PerfectScrollbar>
    </Dialog>
  )

}

export default Settings
